@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;

  @include media-breakpoint-up(md) {
    height: unset;
  }
}

.mobileLayoutContainer {
  position: relative;
  height: 100%;
  @include media-breakpoint-up(md) {
    height: unset;
  }
}

.sampleListSidebar {
  padding: 1rem;
  padding-top: 2rem;
  position: absolute;
  flex-direction: column;
  gap: 1rem;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;

  display: none;
  .mobileLayoutContainer.sampleList & {
    display: flex;
  }

  @include media-breakpoint-up(sm) {
    max-width: map-get($container-max-widths, sm);
    left: 50%;
    transform: translateX(-50%);
  }

  @include media-breakpoint-up(md) {
    max-width: unset;
    left: 0;
    transform: unset;
    display: flex;
    position: fixed;
    width: var(--sidebar-width);
    top: 115px;
  }
}

.mainLayout {
  flex-grow: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  overflow-y: auto;

  display: none;
  .mobileLayoutContainer.currentSample & {
    display: flex;
  }

  @include media-breakpoint-up(sm) {
    // set horizontal padding to match title bar
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    position: unset;
    width: unset;
    overflow-y: unset;
    margin-left: var(--sidebar-width);
  }

  & > [class*='container'] {
    flex-grow: 1;
  }
}

.mobileFooterContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  overflow-y: auto;

  display: none;
  .mobileLayoutContainer.about & {
    display: unset;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  h2 {
    padding: 2rem 1rem 0;
  }
}

.normalFooterContainer {
  padding-top: 3rem;
  display: none;

  @include media-breakpoint-up(md) {
    display: unset;
  }
}

.mobilePageNav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-size: 1.25rem;
  padding: 0.5rem;
  background-color: #f3f3f3;
  border-top: 1px solid #ccc;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
