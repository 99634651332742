@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.titleBar {
  display: flex;
  background-color: #f3f3f3;
  padding-top: 1rem;
  padding-bottom: 0;
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  z-index: 1030;

  @include media-breakpoint-up(md) {

    & > [class*="container"] {
      margin: 0 0.5rem;
    }
  }
}

.title {
  --stroke-width: 1px;
  --stroke-color: var(--bs-dark);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  @media screen and (min-width: 390px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 530px) and (min-height: 500px) {
    font-size: 3rem;
  }

  @media screen and (min-width: 600px) and (min-height: 600px) {
    font-size: 3.5rem;
  }
}

.titleText {
  color: var(--bs-primary);
  white-space: nowrap;
  line-height: 1em;
  align-items: center;
  transition: transform 0.1s;
  will-change: transform;
  .title:hover & {
    transform: scale(1.05);
  }
  position: relative;
  &::after {
    position: absolute;
    left: 0;
    content: attr(data-text);
    z-index: -1;
    // only half of the stroke will be visible
    -webkit-text-stroke: calc(2 * var(--stroke-width)) var(--stroke-color);
  }
}

.titleR {
  font-size: 1.3em;
  vertical-align: middle;
  text-decoration: underline;
  text-decoration-color: var(--stroke-color);
  position: relative;
  &::after {
    position: absolute;
    left: 0;
    // top/transform apparently needed by firefox but not chrome
    top: 50%;
    transform: translateY(-50%);
    content: 'r';
    z-index: -1;
    // only half of the stroke will be visible
    -webkit-text-stroke: calc(2 * var(--stroke-width)) var(--stroke-color);
  }
}

.titleStarburst {
  z-index: -2;
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.1s, height 0.1s;
  will-change: width, height;
  .title:hover & {
    // 75 is the height of a starburst ray at full scale.
    // 56 is the maximum font size displayed (3.5rem).
    // At full scale, width will be 56px * 6 * 75 / 56 = 6px * 75 = 450px.
    // At full scale, height will be 56px * 2 * 75 / 56 = 2px * 75 = 150px.
    width: calc(6em * 75 / 56);
    height: calc(2em * 75 / 56);
  }
  & > polygon {
    --rotation: 0deg;
    transform: rotate(var(--rotation));
    fill: gold;
  }
}

.titleGraphic {
  margin-left: 1.5rem;
  transition: transform 0.1s;
  will-change: transform;
  .title:hover & {
    transform: rotate(-5deg) scale(1.1);
  }

  img {
    width: 2.5em;
    filter: url(#outline);
  }
}

.tagline {
  position: absolute;
  will-change: top, left, transform;
  transition: top 0.1s, left 0.1s, transform 0.1s;
  transform: scale(0);
  top: 1.2em;
  left: 0.7em;

  @media screen and (max-width: 529px) and (max-height: 499px) {
    font-size: 1.8rem;
    h2 {
      font-size: inherit;
    }
  }

  @media screen and (min-width: 530px) and (min-height: 500px) {
    transform: scale(1);
    top: 1.1em;
    left: 0.5em;
  }

  .title:hover & {
    transform: scale(1);
    top: 1.2em;
    left: 0.7em;
  }
}
