@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.footer {
  padding: 1rem 1rem 0;

  @include media-breakpoint-up(md) {
    text-align: center;
  }

  p {
    @include media-breakpoint-up(md) {
      margin-bottom: 0.25rem;
    }
  }

  .faqAndLicensesButton {
    display: none;
    @include media-breakpoint-up(md) {
      display: unset;
    }
  }

  .faqAndLicensesEmbedded {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.qAndA > p:first-child {
  margin-bottom: 0.5rem;
}
