$form-range-track-bg: green;

@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';


.slotNumbersTable {
  table-layout: fixed;
}
.rangeLabels {
  width: 78px;
}

.transferButton {
  display: block;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: unset;
  }
}

.preTransferModal {
  figure {
    text-align: center;

    img {
      max-width: 100%;
      max-height: 400px;
    }
  }
}

.subtask {
  margin-top: 1rem;
}

.secondaryProgress {
  height: 0.25rem;
}

.progressAnnotation {
  display: flex;
  justify-content: flex-end;
}
