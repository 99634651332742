@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  --record-ui-width: 250px;

  @include media-breakpoint-up(sm) {
    --record-ui-width: 300px;
  }

  & > * {
    margin-bottom: 1rem;
  }

  .audioInputHeader {
    width: var(--record-ui-width);

    button > div {
      overflow: hidden;
      width: 100%;
    }

    p {
      font-family: var(--body-font-family);
      color: var(--bs-dark);
      margin: 0.25rem 0;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $text-muted;
      padding-right: 0.5rem;
    }
  }

  select {
    margin-bottom: 1rem;
  }

  & > h2 {
    text-align: center;
    max-width: var(--record-ui-width);
  }

  & > button {
    width: var(--record-ui-width);
  }

  --audio-input-accordion-inner-width: calc(
    var(--record-ui-width) - #{$accordion-padding-x * 2}
  );

  select {
    width: var(--audio-input-accordion-inner-width);
  }

  .channelOptionSelect {
    display: flex; // inline-flex is the default for btn-group
  }

  .channelOption {
    width: calc(var(--audio-input-accordion-inner-width) / 2);
    text-align: center;
    margin-bottom: 0.5rem;

    &:disabled {
      color: $text-muted !important;
      pointer-events: initial;
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: 0;
      box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
        rgba($secondary, 0.5);
    }
  }

  .stereoExplanation {
    text-align: center;
    margin-top: 0.25rem;
    margin-bottom: 0;
  }

  .alertContainer {
    width: var(--record-ui-width);
    & > * {
      display: inline-block;
    }
  }
}

.recordButton {
  position: relative;
  overflow: hidden;
  height: 80px;
  font-size: 1.6rem;

  & > .mainText {
    position: relative;
    z-index: 2;
  }

  & > .timeRecorded {
    color: #fff;
    opacity: 0.7;
    position: absolute;
    font-size: 14px;
    z-index: 1;
    top: 0.25rem;
    right: 0.5rem;
  }

  & > canvas {
    position: absolute;
    opacity: 0.7;
    z-index: 0;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // max recording length is 65 sec but we show only 10 secs at a time
    width: 650%;
    height: 100%;
    image-rendering: pixelated;
    will-change: left;
  }
}

.importFileButton {
  margin-top: 1.5rem;
}

.osTabs {
  margin-bottom: 1rem;
}

.systemRecordModalBody {
  img {
    cursor: pointer;
    margin-bottom: 1rem;
  }
}
