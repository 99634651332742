@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.waveformContainer {
  --trim-pixels-left: 0px;
  --trim-pixels-right: 0px;
  --waveform-width: 0px; // defined by javascript
  filter: var(--grayscale-filter);
  mix-blend-mode: darken;
  position: relative;
  width: var(--waveform-width);
  height: 40px;

  &.playbackActive {
    filter: unset;
  }
}

.noSelect {
  user-select: none;
}

div.listItem {
  cursor: initial;
  text-align: left;
  overflow: hidden;
  &:hover {
    background-color: var(--bs-gray-100);
  }

  &:hover,
  &.active,
  &:focus,
  &:focus-visible {
    color: var(--bs-primary);

    .waveformContainer {
      filter: unset;
    }
  }

  &:focus,
  &:focus-visible {
    outline: $input-btn-focus-width solid $input-btn-focus-color;
    outline-offset: -$input-btn-focus-width;
  }

  .sampleTitle {
    white-space: nowrap;
    display: flex;
    margin-bottom: 0.5rem;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
      flex-grow: 1;
    }

    .slot {
      font-size: 0.8rem;
      font-family: monospace;
      opacity: 0.6;
      margin-left: 0.5rem;
      line-height: 1.5rem;
    }
  }

  .multiSelector {
    align-self: center;
    margin-right: 1rem;
    display: none;

    .multiSelect & {
      display: unset;
    }
  }

  .playbackButton {
    border-radius: 50%;
    position: relative;
    padding: 14px;
    margin-right: 0.5rem;

    & > svg {
      position: absolute;
      fill: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.emptyListMessage {
  color: $text-muted;
}
