.waveformContainer {
  --trim-pixels-left: 0px;
  --trim-pixels-right: 0px;
  position: relative;
  background-color: #fff;
  outline: 1px solid #ddd;
  width: 100%;
  height: 120px;
  border-radius: 1px;
  margin-bottom: 2rem;
}
