:root {
  --grayscale-filter: grayscale(100%) brightness(200%);
  --body-font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --sidebar-width: 330px;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Kalam, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* by default the z-index is 1000 which is insane */
.range-slider__tooltip {
  z-index: 1 !important;
}

a:not(.dropdown-item),
.btn-link {
  font-weight: bold !important;
  padding: 0 !important;
  vertical-align: unset !important;
  user-select: unset !important;
}

pre {
  color: #fff;
  background-color: var(--bs-dark);
  padding: 1rem;
  border-radius: 0.3rem;
}

img {
  max-width: 100%;
}
