@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

@include media-breakpoint-up(md) {
  .importModal > * {
    // We don't always have enough text content to fill up the modal space
    // but it's weird for the modal to be changing size all the time.
    width: var(--bs-modal-width);
  }
}
