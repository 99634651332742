.playbackOverlay {
  --playback-progress: 0%;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: var(--trim-pixels-left);
  width: calc(100% - var(--trim-pixels-left) - var(--trim-pixels-right));
  will-change: left, width;
  display: none;

  &.playbackActive {
    display: initial;
  }

  & > .playback {
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--playback-progress);
    will-change: left;
    right: 0;
    backdrop-filter: var(--grayscale-filter);
  }
}

.playbackButtonContainer {
  position: absolute;
  z-index: 1;
  left: 0.5rem;
  top: 0.5rem;
  display: flex;
  align-items: center;
  mix-blend-mode: luminosity;

  & > button {
    border-radius: 50%;
    padding: 1.2rem;
    position: relative;

    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      // default size is 1.5rem and we want 2rem
      transform: translate(-50%, -50%) scale(calc(2 / 1.5));
      fill: #fff;
    }
  }

  & > span {
    margin-left: 0.5rem;
    color: #fff;
    background: var(--bs-dark);
    padding: 0 3px;
    border-radius: 4px;
  }
}

.downloadButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  mix-blend-mode: luminosity;

  & > svg {
    fill: #fff;
  }
}
