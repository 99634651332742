.sampleMenuButtonFullWidth {
  width: 100%;
}

.sampleMenuButtonsContainer {
  @extend .sampleMenuButtonFullWidth;
  display: flex;
  gap: 0.5rem;

  & > button {
    width: 100%;
  }
}

button.exporting {
  position: relative;

  span {
    position: relative;
    z-index: 2;
  }

  .progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: unset;
    opacity: 0.5;
  }
}

.search {
  input {
    border-radius: var(--bs-border-radius) !important;
  }

  .clearSearch {
    position: absolute;
    z-index: 5;
    background-color: transparent;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    font-size: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;

    &::after {
      content: '×';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 0;
    }
  }
}

.offcanvasBody {
  display: flex;
  flex-direction: column;
}

.listGroup {
  min-height: 0;
}

.accordion {
  display: flex;
  flex-direction: column;
  // 1px greater than the height.. TODO: find a way not to hardcode ?
  --accordion-header-min-height: 55px;
  --empty-message-height: 48px;
  --result-height: 95px;
  --results-count: 0; // specified by javascript
  --accordion-open: 0; // 0 or 1, specified by javascript
  --min-height: min(
    calc(
      /* we always need to see the accordion header */
        var(--accordion-header-min-height) +
        (
          /* multiply results height by 0 or 1 for closed / open */
            var(--accordion-open) *
            max(
              var(--empty-message-height),
              calc(var(--results-count) * var(--result-height))
            ) - 1px
        )
    ),
    calc(100vh / 3)
  );
  min-height: var(--min-height);

  &:not(:first-child) > .accordionItem {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:last-child) {
    margin-bottom: -1px;
    & > .accordionItem {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.accordionItem {
  display: flex;
  flex-direction: column;
  min-height: var(--min-height);

  // accordion collapse wraps body.. not directly accessible
  & > div {
    overflow-y: auto;
  }
}

.accordionBody {
  padding: 0;
}

// This is needed to accomodate for the styles on .modal-content which is
// supposed to be the parent of .modal-body
.deleteModalForm {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.deleteModalHeader {
  justify-content: flex-start;

  & > svg {
    fill: var(--bs-primary);
    margin-right: 1rem;
    transform: scale(calc(2 / 1.5));
  }
}
