@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

@include media-breakpoint-up(md) {
  .pluginConfirmModal > * {
    // We don't always have enough text content to fill up the modal space
    // but it's weird for the modal to be changing size all the time.
    width: var(--bs-modal-width);
  }
}

.pluginConfirmModal {
  background-color: rgba(0, 0, 0, 0.35);

  & > * {
    flex-direction: row;
  }
}
