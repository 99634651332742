@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.slotNumberRow {
  display: flex;
  margin-bottom: 1.5rem;
}

.slotNumberContainer {
  --slot-width: 160px;
  --slot-padding: 0.25rem;
  --slot-bg-color: var(--bs-dark);
  width: var(--slot-width);
}

.arrowControls {
  display: flex;
  justify-content: flex-end;
  width: var(--slot-width);
  height: 2rem;
  padding-left: var(--slot-padding);
  padding-right: var(--slot-padding);

  & > span {
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.slotNumber {
  background-color: var(--slot-bg-color);
  display: inline-block;
  width: var(--slot-width);
  padding: var(--slot-padding);
  border-radius: var(--slot-padding);
  position: relative;
  cursor: text;

  & > svg {
    width: calc(var(--slot-width) - var(--slot-padding) * 2);
    transform: skewX(-4deg);

    // digits background outlines
    &:first-child {
      position: absolute;
      top: 0.25rem;
      opacity: 0.7;
    }

    polygon {
      stroke: var(--slot-bg-color);
    }

    g {
      pointer-events: bounding-box;

      &.active {
        animation-name: digitBlinkEffect;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }

    circle.point {
      fill: var(--bs-primary);
    }
  }

  &:focus,
  &:focus-visible {
    outline: 0;
    box-shadow: $input-btn-focus-box-shadow;
  }
}

.warning {
  margin-left: 1rem;
  align-self: center;

  & > svg {
    fill: var(--bs-warning);
  }
}

@keyframes digitBlinkEffect {
  from {
    visibility: hidden;
  }
  20% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
}
