@import 'bootstrap_custom_variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.pitchAdjustmentWrapper {
  --collapse-buffer-x: 1rem;
  max-width: calc(400px + var(--collapse-buffer-x) * 2);
  margin-bottom: 2rem;

  .label {
    margin-bottom: 0;

    .previewValue {
      font-weight: bold;
      position: relative;
      left: 2px;
      width: 0;
      display: inline-flex;
    }

    &::before {
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3rem solid transparent;
      margin-right: 0.3em;
      display: inline-block;
      vertical-align: 0.2em;
      transform: rotate(-90deg);
      transition: transform 0.35s ease;
    }
  }

  &.expanded .label {
    .previewValue {
      display: none;
    }

    &::before {
      transform: rotate(0deg);
    }
  }

  .label + * {
    padding-left: var(--collapse-buffer-x);
    padding-right: var(--collapse-buffer-x);
    margin-left: calc(0px - var(--collapse-buffer-x));
    margin-right: calc(0px - var(--collapse-buffer-x));
  }

  .ticks {
    margin-top: 2rem;
    position: relative;
  }

  .tickLabel {
    color: $text-muted;
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
  }

  .tickMark {
    position: absolute;
    top: 0.5rem;
    width: 1px;
    height: 0.25rem;
    background-color: $text-muted;
  }

  .annotations {
    transform: translateY(-50%);
    margin-bottom: -0.5em;
    display: flex;
    justify-content: space-between;
    color: $text-muted;

    > .warn {
      color: var(--bs-primary);
    }
  }
}
